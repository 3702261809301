import React from 'react'
//import { StaticImage } from "gatsby-plugin-image"

import ReactWeather, { useOpenWeather } from 'react-open-weather';
//import ReactWeather from "react-open-weather";
//Optional include of the default css styles

import { Helmet } from "react-helmet"


import styled from 'styled-components';

import Layout from '../../../components/Layout'

const customStyles = {
	fontFamily:  'Helvetica, sans-serif',
    gradientStart:  '#FFF',
	gradientMid:  '#FFF',
	gradientEnd:  '#FFF',
	locationFontColor:  '#000',
	todayTempFontColor:  '#000',
	todayDateFontColor:  '#000',
	todayRangeFontColor:  '#000',
	todayDescFontColor:  '#000',
	todayInfoFontColor:  '#000',
	todayIconColor:  '#000',
	forecastBackgroundColor:  '#FFF',
	forecastSeparatorColor:  '#FFF',
	forecastDateColor:  '#777',
	forecastDescColor:  '#777',
	forecastRangeColor:  '#777',
	forecastIconColor:  '#777',
};

const TravelWraper = styled.div`
  text-align: center;
  margin: 0 auto;
  height: auto;
  width: 80vw;  

  h1 {
    font-size: 70px;
  }  

  @media screen and (max-width: 960px) {
    h1 {
      font-size: 40px;
      font-weight: 900;
    }
    h2 {
      font-size: 30px;      
      font-weight: 900;
      margin-bottom: 20px;
    }
  }

`

const Clima = () => {
    const { data, isLoading, errorMessage } = useOpenWeather({
      key: 'f6973195b3a29969a6ad7e393d2ac38b',
      lat: '-34.5798993',
      lon: '-59.1858183',
      lang: 'en',
      unit: 'metric', // values are (metric, standard, imperial)
    });
    return (
      <ReactWeather
        isLoading={isLoading}
        theme={customStyles}
        errorMessage={errorMessage}
        data={data}
        lang="en"
        locationLabel="Estancia Santa Elena"
        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
        showForecast
      />
    );
  };


const Travel = () => {

  return (
    <Layout section="travel" pageTitle="Travel">
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/weather-icons/2.0.9/css/weather-icons.min.css" type="text/css"/>
      </Helmet>    
        <TravelWraper>
            <h1>Weather</h1>
            <Clima />
        </TravelWraper>            
    </Layout>
  )
}

export default Travel